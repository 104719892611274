// @codekit-prepend "../../../node_modules/bricks.js/dist/bricks.js"
// @codekit-prepend "../../../node_modules/embla-carousel/embla-carousel.umd.js"
// @codekit-prepend "../../../node_modules/embla-carousel-auto-height/embla-carousel-auto-height.umd.js"
// @codekit-prepend "../../../node_modules/embla-carousel-autoplay/embla-carousel-autoplay.umd.js"
// @codekit-prepend "../../../node_modules/embla-carousel-class-names/embla-carousel-class-names.umd.js"
// @codekit-prepend "../../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js"

(function ($) {
	/* Breakpoints */
	const breakpoints = { lg: 1200, md: 992, sm: 768, xs: 480, nav: 1151 };

	/* Check Window Width */
	let resizeTimeout = null;
	let cachedWindowWidth = $(window).width();

	// Check if window width has actually changed
	function checkWindowWidth() {
		const newWindowWidth = $(window).width();

		if (newWindowWidth !== cachedWindowWidth) {
			// If changed call functions on resize
			updateLogoColor();

			if (twoColMasonryContainerEl.length) {
				twoColMasonryLayout();
			}

			if (threeColMasonryContainerEl.length) {
				threeColMasonryLayout();
			}

			cachedWindowWidth = newWindowWidth;
		}
	}
	$(window).on("resize", function () {
		if (resizeTimeout !== null) {
			clearTimeout(resizeTimeout);
		}

		resizeTimeout = setTimeout(checkWindowWidth, 50);
	});

	/* Format Phone Numbers */
	// Strip non-numeric characters from phone numbers and add to href if element contains `tel` class
	function formatPhoneNumbers() {
		const phoneNumbers = $(".tel");

		phoneNumbers.each((i, el) => {
			let phoneNumber;
			const extension = $(".tel + .ext");

			// Remove '1-' prefix before formatting if it exists
			if ($(el).text().substring(0, 2) === "1-") {
				phoneNumber = $(el).text().replace("1-", "");
			} else {
				phoneNumber = $(el).text();
			}

			// Set phone number, add extension if it exists
			if (extension.length) {
				phoneNumber =
					"tel:1" +
					phoneNumber.replace(/\D/g, "") +
					";ext=" +
					extension.data("ext");
			} else {
				phoneNumber = "tel:1" + phoneNumber.replace(/\D/g, "");
			}

			$(el).attr("href", phoneNumber);
		});
	}
	formatPhoneNumbers();

	/* Sliders */
	function embla() {
		// Previous and next buttons
		const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
			prevBtn.addEventListener("click", embla.scrollPrev, false);
			nextBtn.addEventListener("click", embla.scrollNext, false);
		};

		const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
			return () => {
				if (embla.canScrollPrev()) prevBtn.removeAttribute("disabled");
				else prevBtn.setAttribute("disabled", "disabled");

				if (embla.canScrollNext()) nextBtn.removeAttribute("disabled");
				else nextBtn.setAttribute("disabled", "disabled");
			};
		};

		// Dot navigation
		const setupDotBtns = (dotsArray, embla) => {
			dotsArray.forEach((dotNode, i) => {
				dotNode.addEventListener(
					"click",
					() => embla.scrollTo(i),
					false
				);
			});
		};

		const generateDotBtns = (dots, embla) => {
			const template =
				document.getElementById("embla-dot-template").innerHTML;
			dots.innerHTML = embla
				.scrollSnapList()
				.reduce((acc) => acc + template, "");
			return [].slice.call(dots.querySelectorAll(".embla__dot"));
		};

		const selectDotBtn = (dotsArray, embla) => () => {
			const previous = embla.previousScrollSnap();
			const selected = embla.selectedScrollSnap();
			dotsArray[previous].classList.remove("is-selected");
			dotsArray[selected].classList.add("is-selected");
		};

		// Progress bar
		const setupProgressBar = (embla, progressNode) => {
			const applyProgress = () => {
				const progress = Math.max(
					0,
					Math.min(1, embla.scrollProgress())
				);
				progressNode.style.transform = `translateX(${progress * 100}%)`;
			};

			const removeProgress = () => {
				progressNode.removeAttribute("style");
			};

			return {
				applyProgress,
				removeProgress
			};
		};

		// Autoplay
		const autoPlay = (embla, interval) => {
			const lastIndex = embla.scrollSnapList().length - 1;
			const state = { timer: 0 };

			const play = () => {
				stop();
				requestAnimationFrame(
					() => (state.timer = window.setTimeout(next, interval))
				);
			};

			const stop = () => {
				window.clearTimeout(state.timer);
				state.timer = 0;
			};

			const next = () => {
				if (embla.selectedScrollSnap() === lastIndex) {
					embla.scrollTo(0);
				} else {
					embla.scrollNext();
				}
				play();
			};

			return { play, stop };
		};

		// Tween scale
		const tweenScale = (embla, interval) => {
			const TWEEN_FACTOR = 3;

			const numberWithinRange = (number, min, max) =>
				Math.min(Math.max(number, min), max);

			const calculateTweenValuesScale = (embla) => {
				const engine = embla.internalEngine();
				const scrollProgress = embla.scrollProgress();

				return embla.scrollSnapList().map((scrollSnap, index) => {
					if (!embla.slidesInView().includes(index)) return 0;
					let diffToTarget = scrollSnap - scrollProgress;

					if (engine.options.loop) {
						engine.slideLooper.loopPoints.forEach((loopItem) => {
							const target = loopItem.target();
							if (index === loopItem.index && target !== 0) {
								const sign = Math.sign(target);
								if (sign === -1)
									diffToTarget =
										scrollSnap - (1 + scrollProgress);
								if (sign === 1)
									diffToTarget =
										scrollSnap + (1 - scrollProgress);
							}
						});
					}
					const tweenValue =
						1 - Math.abs(diffToTarget * TWEEN_FACTOR);
					return numberWithinRange(tweenValue, 0, 1);
				});
			};

			const tweenNodes = embla
				.slideNodes()
				.map((slideNode) => slideNode.querySelector(".embla__scale"));

			const applyTweenScale = () => {
				const tweenValues = calculateTweenValuesScale(embla);
				tweenValues.forEach((tweenValue, index) => {
					tweenNodes[index].style.transform = `scale(${tweenValue})`;
				});
			};

			const removeTweenScale = () => {
				tweenNodes.forEach((slide) => slide.removeAttribute("style"));
			};

			return {
				applyTweenScale,
				removeTweenScale
			};
		};

		// Banner slider
		function bannerTextImageSlider() {
			const wrap = document.querySelector(
				".banner-text-image-slider .embla"
			);
			const viewPort = wrap.querySelector(
				".banner-text-image-slider .embla__viewport"
			);
			// const prevBtn = wrap.querySelector(".banner-text-image-slider .embla__button--prev");
			// const nextBtn = wrap.querySelector(".banner-text-image-slider .embla__button--next");
			const dots = wrap.querySelector(
				".banner-text-image-slider .embla__dots"
			);
			const embla = EmblaCarousel(
				viewPort,
				{
					// align: "start",
					// axis: "y",
					// breakpoints: {
					// 	"(min-width: 768px)": { dragFree: false },
					// 	// "(min-width: 1201px)": { align: "center" },
					// },
					delay: 1,
					// dragFree: false,
					// inViewThreshold: 1,
					loop: true
					// skipSnaps: false,
					// slidesToScroll: 1,
					// speed: 2
					// startIndex: -1,
				},
				// [
				// 	EmblaCarouselAutoplay({
				// 		delay: 2000
				// 		// jump: true
				// 	})
				// ],
				[EmblaCarouselClassNames()]
			);

			const dotsArray = generateDotBtns(dots, embla);
			const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
			// const disablePrevAndNextBtns = disablePrevNextBtns(
			//   prevBtn,
			//   nextBtn,
			//   embla
			// );

			// setupPrevNextBtns(prevBtn, nextBtn, embla);
			setupDotBtns(dotsArray, embla);

			const toggleEmblaReady = (event) => {
				const isResizeEvent = event === "resize";
				const toggleClass = isResizeEvent ? "remove" : "add";
				viewPort.classList[toggleClass]("embla--is-ready");
				if (isResizeEvent) embla.reInit();
			};

			// Add 'not-in-view' class to slides not in view
			// This mainly used for removing the box-shadow on not-in-view slides
			// function slidesNotInViewStyles() {
			// 	const slideNodes = embla.slideNodes();
			// 	const slidesNotInView = embla.slidesNotInView();

			// 	for (let i = 0; i < slideNodes.length; i++) {
			// 		if (slidesNotInView.includes(i)) {
			// 			slideNodes[i].classList.add("not-in-view");
			// 		} else {
			// 			slideNodes[i].classList.remove("not-in-view");
			// 		}
			// 	}
			// }
			// slidesNotInViewStyles();

			embla.on("select", setSelectedDotBtn);
			// embla.on("select", disablePrevAndNextBtns);
			embla.on("init", setSelectedDotBtn);
			// embla.on("init", disablePrevAndNextBtns);
			// embla.on("scroll", slidesNotInViewStyles);
			embla.on("init", toggleEmblaReady);
			embla.on("resize", toggleEmblaReady);
			embla.on("reInit", toggleEmblaReady);

			// Inactivate transform
			// embla.internalEngine().translate.toggleActive(false);
			// embla.internalEngine().translate.clear();

			// Autoplay
			const auto = autoPlay(embla, 3000);
			viewPort.addEventListener("mouseenter", auto.stop, false);
			viewPort.addEventListener("mouseleave", auto.play, false);
			viewPort.addEventListener("touchstart", auto.stop, false);
			auto.play();
		}
		const bannerTextImageSliderEl = document.querySelector(
			".banner-text-image-slider"
		);
		if (bannerTextImageSliderEl) {
			bannerTextImageSlider();
		}

		// Services slider
		function servicesSlider() {
			const wrap = document.querySelector(".services.layout-home .embla");
			const viewPort = wrap.querySelector(
				".services.layout-home .embla .embla__viewport"
			);
			// const prevBtn = wrap.querySelector(".banner-text-image-slider .embla__button--prev");
			// const nextBtn = wrap.querySelector(".banner-text-image-slider .embla__button--next");
			// const dots = wrap.querySelector(".services.layout-home .embla .embla__dots");
			const progressNode = wrap.querySelector(".embla__progress__bar");
			const embla = EmblaCarousel(
				viewPort,
				{
					active: true,
					align: "start",
					breakpoints: {
						"(min-width: 768px)": { active: false }
					},
					dragFree: true,
					// inViewThreshold: 1,
					loop: true
					// skipSnaps: false,
					// slidesToScroll: 1,
					// startIndex: -1,
				},
				[EmblaCarouselClassNames()]
			);

			// const dotsArray = generateDotBtns(dots, embla);
			// const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
			// const disablePrevAndNextBtns = disablePrevNextBtns(
			//   prevBtn,
			//   nextBtn,
			//   embla
			// );

			// setupPrevNextBtns(prevBtn, nextBtn, embla);
			// setupDotBtns(dotsArray, embla);

			const toggleEmblaReady = (event) => {
				const isResizeEvent = event === "resize";
				const toggleClass = isResizeEvent ? "remove" : "add";
				viewPort.classList[toggleClass]("embla--is-ready");
				if (isResizeEvent) embla.reInit();
			};

			// Add 'not-in-view' class to slides not in view
			// This mainly used for removing the box-shadow on not-in-view slides
			// function slidesNotInViewStyles() {
			//   const slideNodes = embla.slideNodes();
			//   const slidesNotInView = embla.slidesNotInView();

			//   for (let i = 0; i < slideNodes.length; i++) {
			//     if (slidesNotInView.includes(i)) {
			//       slideNodes[i].classList.add("not-in-view");
			//     } else {
			//       slideNodes[i].classList.remove("not-in-view");
			//     }
			//   }
			// }
			// slidesNotInViewStyles();

			const { applyProgress, removeProgress } = setupProgressBar(
				embla,
				progressNode
			);

			// embla.on("select", setSelectedDotBtn);
			// embla.on("select", disablePrevAndNextBtns);
			// embla.on("init", setSelectedDotBtn);
			// embla.on("init", disablePrevAndNextBtns);
			// embla.on("scroll", slidesNotInViewStyles);

			if (cachedWindowWidth < 768) {
				embla.on("init", applyProgress);
				embla.on("reInit", applyProgress);
				embla.on("scroll", applyProgress);
				embla.on("destroy", removeProgress);
			}

			embla.on("init", toggleEmblaReady);
			embla.on("resize", toggleEmblaReady);
			embla.on("reInit", toggleEmblaReady);
		}
		const servicesSliderEl = document.querySelector(
			".services.layout-home .embla"
		);
		if (servicesSliderEl) {
			servicesSlider();
		}

		// Text slider
		function textSlider() {
			const wrap = document.querySelector(".text-slider.embla");
			const viewPort = wrap.querySelector(
				".text-slider.embla .embla__viewport"
			);
			// const prevBtn = wrap.querySelector(".text-slider.embla .embla__button--prev");
			// const nextBtn = wrap.querySelector(".text-slider.embla .embla__button--next");
			const dots = wrap.querySelector(".text-slider.embla .embla__dots");
			const embla = EmblaCarousel(
				viewPort,
				{
					// active: true,
					// align: "center",
					// breakpoints: {
					// 	"(min-width: 768px)": { align: "center" }
					// },
					// containScroll: "trimSnaps",
					// duration: 20
					// dragFree: true,
					// inViewThreshold: 1,
					// loop: true
					// skipSnaps: false,
					// slidesToScroll: 1,
					// startIndex: -1,
				},
				// [
				// 	EmblaCarouselAutoplay({
				// 		delay: 1,
				// 		jump: true
				// 	})
				// ],
				[EmblaCarouselClassNames(), EmblaCarouselAutoHeight()]
			);

			const dotsArray = generateDotBtns(dots, embla);
			const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
			// const disablePrevAndNextBtns = disablePrevNextBtns(
			// 	prevBtn,
			// 	nextBtn,
			// 	embla
			// );

			// setupPrevNextBtns(prevBtn, nextBtn, embla);
			setupDotBtns(dotsArray, embla);

			// const rafId = {};

			// const animate = () => {
			// 	if (!embla || !rafId.current) return;

			// 	const engine = embla.internalEngine();
			// 	engine.location.add(-1);
			// 	engine.target.set(engine.location);
			// 	engine.scrollLooper.loop(-1);
			// 	engine.slideLooper.loop();
			// 	engine.translate.to(engine.location);
			// 	rafId.current = requestAnimationFrame(animate);
			// };

			// const startAutoScroll = () => {
			// 	rafId.current = requestAnimationFrame(animate);
			// };

			// const stopAutoScroll = () => {
			// 	rafId.current = cancelAnimationFrame(rafId.current) || 0;
			// };

			// const stopAutoScroll = () => {
			// 	console.log("Stop autoscroll...");
			// 	const engine = embla.internalEngine();

			// 	engine.scrollBody.useSpeed(0);
			// };

			const toggleEmblaReady = (event) => {
				const isResizeEvent = event === "resize";
				const toggleClass = isResizeEvent ? "remove" : "add";
				viewPort.classList[toggleClass]("embla--is-ready");
				if (isResizeEvent) embla.reInit();
			};

			// Add 'not-in-view' class to slides not in view
			// This mainly used for removing the box-shadow on not-in-view slides
			// function slidesNotInViewStyles() {
			// 	const slideNodes = embla.slideNodes();
			// 	const slidesNotInView = embla.slidesNotInView();

			// 	for (let i = 0; i < slideNodes.length; i++) {
			// 		if (slidesNotInView.includes(i)) {
			// 			slideNodes[i].classList.add("not-in-view");
			// 		} else {
			// 			slideNodes[i].classList.remove("not-in-view");
			// 		}
			// 	}
			// }
			// slidesNotInViewStyles();

			embla.on("select", setSelectedDotBtn);
			// embla.on("select", disablePrevAndNextBtns);
			embla.on("init", setSelectedDotBtn);
			// embla.on("init", disablePrevAndNextBtns);
			// embla.on("scroll", slidesNotInViewStyles);

			embla.on("init", toggleEmblaReady);
			embla.on("resize", toggleEmblaReady);
			embla.on("reInit", toggleEmblaReady);
			// embla.on("settle", startAutoScroll);
			// embla.on("pointerDown", stopAutoScroll);
			// embla.on("settle", startAutoScroll);
			// startAutoScroll();

			// Tween scale
			const { applyTweenScale, removeTweenScale } = tweenScale(embla);
			embla.on("init", applyTweenScale);
			embla.on("scroll", applyTweenScale);
			embla.on("reInit", applyTweenScale);
			embla.on("destroy", removeTweenScale);

			// Autoplay
			const auto = autoPlay(embla, 5000);
			viewPort.addEventListener("mouseenter", auto.stop, false);
			viewPort.addEventListener("mouseleave", auto.play, false);
			viewPort.addEventListener("touchstart", auto.stop, false);
			auto.play();
		}
		const textSliderEl = document.querySelector(".text-slider.embla");
		if (textSliderEl) {
			textSlider();
		}

		// Logo slider
		function logoSlider() {
			const wrap = document.querySelector(".logo-slider.embla");
			const viewPort = wrap.querySelector(
				".logo-slider.embla .embla__viewport"
			);
			const embla = EmblaCarousel(
				viewPort,
				{
					// active: true,
					align: "center",
					// breakpoints: {
					// 	"(min-width: 768px)": { active: true }
					// },
					dragFree: true,
					// inViewThreshold: 1,
					loop: true
					// skipSnaps: false,
					// slidesToScroll: 1,
					// startIndex: -1,
				},
				[EmblaCarouselClassNames()]
			);

			// const dotsArray = generateDotBtns(dots, embla);
			// const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
			// const disablePrevAndNextBtns = disablePrevNextBtns(
			//   prevBtn,
			//   nextBtn,
			//   embla
			// );

			// setupPrevNextBtns(prevBtn, nextBtn, embla);
			// setupDotBtns(dotsArray, embla);

			// Autoscroll
			// const startAutoScroll = () => {
			// 	if (cachedWindowWidth < 768) {
			// 		return false;
			// 	}

			// 	// console.log("Start autoscroll...");
			// 	const engine = embla.internalEngine();
			// 	console.log(engine.location);

			// 	// engine.scrollBody.useSpeed(0.5);
			// 	// engine.scrollTo.index(embla.scrollSnapList().length + 1);
			// 	// engine.location = 1;
			// 	engine.location.add(-10);
			// 	engine.target.set(engine.location);
			// 	engine.scrollLooper.loop(-1);
			// 	engine.slideLooper.loop();
			// 	engine.translate.to(engine.location);
			// };

			const rafId = {};

			const animate = () => {
				if (!embla || !rafId.current) return;

				const engine = embla.internalEngine();
				engine.location.add(-1);
				engine.target.set(engine.location);
				engine.scrollLooper.loop(-1);
				engine.slideLooper.loop();
				engine.translate.to(engine.location);
				rafId.current = requestAnimationFrame(animate);
			};

			const startAutoScroll = () => {
				rafId.current = requestAnimationFrame(animate);
			};

			const stopAutoScroll = () => {
				rafId.current = cancelAnimationFrame(rafId.current) || 0;
			};

			const toggleEmblaReady = (event) => {
				const isResizeEvent = event === "resize";
				const toggleClass = isResizeEvent ? "remove" : "add";
				viewPort.classList[toggleClass]("embla--is-ready");
				if (isResizeEvent) embla.reInit();
			};

			// Add 'not-in-view' class to slides not in view
			// This mainly used for removing the box-shadow on not-in-view slides
			// function slidesNotInViewStyles() {
			//   const slideNodes = embla.slideNodes();
			//   const slidesNotInView = embla.slidesNotInView();

			//   for (let i = 0; i < slideNodes.length; i++) {
			//     if (slidesNotInView.includes(i)) {
			//       slideNodes[i].classList.add("not-in-view");
			//     } else {
			//       slideNodes[i].classList.remove("not-in-view");
			//     }
			//   }
			// }
			// slidesNotInViewStyles();

			// embla.on("select", setSelectedDotBtn);
			// embla.on("select", disablePrevAndNextBtns);
			// embla.on("init", setSelectedDotBtn);
			// embla.on("init", disablePrevAndNextBtns);
			// embla.on("scroll", slidesNotInViewStyles);

			embla.on("init", toggleEmblaReady);
			embla.on("resize", toggleEmblaReady);
			embla.on("reInit", toggleEmblaReady);
			embla.on("pointerDown", stopAutoScroll);
			embla.on("settle", startAutoScroll);
			startAutoScroll();
		}
		const logoSliderEl = document.querySelector(".logo-slider.embla");
		if (logoSliderEl) {
			logoSlider();
		}

		// Testimonial slider
		// function testimonialSlider() {
		// 	const wrap = document.querySelector(".testimonial-slider.embla");
		// 	const viewPort = wrap.querySelector(
		// 		".testimonial-slider .embla__viewport"
		// 	);
		// 	const prevBtn = wrap.querySelector(
		// 		".testimonial-slider .embla__button--prev"
		// 	);
		// 	const nextBtn = wrap.querySelector(
		// 		".testimonial-slider .embla__button--next"
		// 	);
		// 	const dots = wrap.querySelector(".testimonial-slider .embla__dots");
		// 	const embla = EmblaCarousel(viewPort, {
		// 		align: "start",
		// 		breakpoints: {
		// 			"(min-width: 768px)": { dragFree: false },
		// 			"(min-width: 1201px)": { align: "center" },
		// 		},
		// 		dragFree: true,
		// 		loop: true,
		// 		// skipSnaps: false,
		// 		// startIndex: -1,
		// 	});

		// 	const dotsArray = generateDotBtns(dots, embla);
		// 	const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
		// 	const disablePrevAndNextBtns = disablePrevNextBtns(
		// 		prevBtn,
		// 		nextBtn,
		// 		embla
		// 	);

		// 	setupPrevNextBtns(prevBtn, nextBtn, embla);
		// 	setupDotBtns(dotsArray, embla);

		// 	// Make all menu item titles the same height
		// 	function sameHeightTestimonials() {
		// 		setTimeout(function () {
		// 			const testimonialEls = $(
		// 				".testimonial-slider.embla .review .excerpt p"
		// 			);
		// 			let largestHeight = 0;

		// 			testimonialEls.each(function () {
		// 				if ($(this).height() > largestHeight) {
		// 					largestHeight = $(this).height();
		// 				}
		// 			});

		// 			testimonialEls.height(largestHeight);
		// 		}, 100);
		// 	}
		// 	sameHeightTestimonials();

		// 	// Add 'not-in-view' class to slides not in view
		// 	// This mainly used for removing the box-shadow on not-in-view slides
		// 	function slidesNotInViewStyles() {
		// 		const slideNodes = embla.slideNodes();
		// 		const slidesNotInView = embla.slidesNotInView();

		// 		for (let i = 0; i < slideNodes.length; i++) {
		// 			if (slidesNotInView.includes(i)) {
		// 				slideNodes[i].classList.add("not-in-view");
		// 			} else {
		// 				slideNodes[i].classList.remove("not-in-view");
		// 			}
		// 		}
		// 	}
		// 	slidesNotInViewStyles();

		// 	embla.on("select", setSelectedDotBtn);
		// 	embla.on("select", disablePrevAndNextBtns);
		// 	embla.on("init", setSelectedDotBtn);
		// 	embla.on("init", disablePrevAndNextBtns);
		// 	embla.on("reInit", sameHeightTestimonials);
		// 	embla.on("scroll", slidesNotInViewStyles);
		// }
		// const testimonialSliderEl = document.querySelector(".testimonial-slider");
		// if (testimonialSliderEl) {
		// 	testimonialSlider();
		// }
	}
	const emblaEl = document.querySelector(".embla");
	if (emblaEl) {
		embla();
	}

	/* Popups */
	// Image popup
	// $('.open-image-link').magnificPopup({
	//   closeBtnInside: true,
	//   closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
	//   type: 'image'
	// });

	// Video popup
	$(".open-video-link").magnificPopup({
		callbacks: {
			open: function () {
				// Add class to mfp-container element for css
				const popup = $(".mfp-iframe");

				if (popup.length) {
					popup.parent().parent().addClass("mfp-video");
				}
			}
		},
		// closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		preloader: false,
		removalDelay: 100,
		type: "iframe"
	});

	// Contact Us popup
	$(".contact-us-form-link, .contact-us-form-link > a").magnificPopup({
		callbacks: {
			open: function () {
				// Add class to mfp-container element for css
				const popup = $(".contact-us-popup");

				if (popup.length) {
					popup.parent().parent().addClass("mfp-contact-us");
				}
			}
		},
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
		items: {
			src: ".contact-us-popup"
		},
		mainClass: "mfp-fade",
		removalDelay: 200,
		type: "inline"
	});

	// Team member popup
	setTimeout(function () {
		$(".open-team-member-link").magnificPopup({
			callbacks: {
				open: function () {
					setTimeout(function () {
						// Add class to mfp-container element for css
						var teamMemberPopup = $(".team-member-popup");

						if (teamMemberPopup.length) {
							teamMemberPopup
								.parent()
								.parent()
								.addClass("mfp-team-member");
						}
					}, 1000);
				},
				parseAjax: function (mfpResponse) {
					mfpResponse.data = $(mfpResponse.data).find("#team-member");
				}
			},
			closeBtnInside: true,
			closeMarkup:
				'<span class="icon-close mfp-close" title="Close"></span>',
			mainClass: "mfp-fade",
			preloader: false,
			removalDelay: 200,
			type: "ajax"
		});
	}, 100);

	// Project (Work) popup
	setTimeout(function () {
		$(".open-project-link").magnificPopup({
			callbacks: {
				open: function () {
					setTimeout(function () {
						// Add class to mfp-container element for css
						var projectPopup = $(".project-popup");

						if (projectPopup.length) {
							projectPopup
								.parent()
								.parent()
								.addClass("mfp-project");
						}
					}, 1000);
				},
				parseAjax: function (mfpResponse) {
					mfpResponse.data = $(mfpResponse.data).find("#project");
				}
			},
			closeBtnInside: true,
			closeMarkup:
				'<span class="icon-close mfp-close" title="Close"></span>',
			mainClass: "mfp-fade",
			preloader: false,
			removalDelay: 200,
			type: "ajax"
		});
	}, 100);

	/* Misc. */
	// Add body classes to special pages (404, search)
	function addBodyClassesToSpecialPages() {
		const logoBlack =
			templateUrl + "/assets/images/content/cynos-logo-black.svg";
		const curMobileSiteHeaderLogoEl = document.querySelector(
			".site-header #ast-mobile-header .custom-logo"
		);

		// Set CSS classes on body
		document.body.classList.add(
			"desktop-header-text-color-black",
			"mobile-header-logo-color-black",
			"mobile-header-text-color-black"
		);

		document.body.classList.remove(
			"desktop-header-text-color-white",
			"mobile-header-logo-color-white",
			"mobile-header-text-color-white"
		);

		// Set logo
		curMobileSiteHeaderLogoEl.src = logoBlack;
	}
	const specialPages = document.querySelector("body.error404, body.search");
	if (specialPages) {
		addBodyClassesToSpecialPages();
	}

	// Switch logo color based on body class
	function updateLogoColor() {
		const logoBlack =
			templateUrl + "/assets/images/content/cynos-logo-black.svg";
		const logoWhite =
			templateUrl + "/assets/images/content/cynos-logo-white.svg";
		const curDesktopSiteHeaderLogoEl = document.querySelector(
			".site-header #ast-desktop-header .custom-logo"
		);
		const curMobileSiteHeaderLogoEl = document.querySelector(
			".site-header #ast-mobile-header .custom-logo"
		);

		// Check if on 404 page, if so get out of here
		const page404 = document.querySelector("body.error404");
		if (page404) {
			return false;
		}

		// Desktop logo color
		// Black (desktop-header-logo-color-black)
		if (
			document.querySelector(".desktop-header-logo-color-black") &&
			cachedWindowWidth > 1080
		) {
			// console.log("Change logo color to black.");
			curDesktopSiteHeaderLogoEl.src = logoBlack;
		}

		// White (desktop-header-logo-color-white)
		if (
			document.querySelector(".desktop-header-logo-color-white") &&
			cachedWindowWidth > 1080
		) {
			// console.log("Change logo color to white.");
			curDesktopSiteHeaderLogoEl.src = logoWhite;
		}

		// Mobile logo color
		// Black (mobile-header-logo-color-black)
		if (
			document.querySelector(".mobile-header-logo-color-black") &&
			cachedWindowWidth < 1081
		) {
			// console.log("Change logo color to black.");
			curMobileSiteHeaderLogoEl.src = logoBlack;
		}

		// White (mobile-header-logo-color-white)
		if (
			document.querySelector(".mobile-header-logo-color-white") &&
			cachedWindowWidth < 1081
		) {
			// console.log("Change logo color to white.");
			curMobileSiteHeaderLogoEl.src = logoWhite;
		}
	}
	updateLogoColor();

	// Masonry layouts
	// Two columns
	function twoColMasonryLayout() {
		// console.log("Repacking!");
		const sizes = [
			{ columns: 1, gutter: 0 },
			{ mq: "768px", columns: 2, gutter: 0 }
			// { mq: "992px", columns: 2, gutter: 0 },
		];

		const masonryLayout = Bricks({
			container:
				".two-col-masonry-container, .blog .site-main > .ast-row, .archive .site-main > .ast-row, .search .site-main > .ast-row, .archive .site-main > .ast-row",
			packed: "data-packed",
			// position: false,
			sizes: sizes
		});

		masonryLayout.pack();
	}
	const twoColMasonryContainerEl = document.querySelectorAll(
		".two-col-masonry-container, .blog .site-main > .ast-row, .archive .site-main > .ast-row, .search .site-main > .ast-row, .archive .site-main > .ast-row"
	);
	if (twoColMasonryContainerEl.length) {
		setTimeout(function () {
			twoColMasonryLayout();
		}, 100);
	}

	// Three columns
	function threeColMasonryLayout() {
		// console.log("Repacking!");
		const sizes = [
			{ columns: 1, gutter: 0 },
			{ mq: "768px", columns: 3, gutter: 0 }
			// { mq: "992px", columns: 2, gutter: 0 },
		];

		const masonryLayout = Bricks({
			container: ".three-col-masonry-container",
			packed: "data-packed",
			// position: false,
			sizes: sizes
		});

		masonryLayout.pack();
	}
	const threeColMasonryContainerEl = document.querySelectorAll(
		".three-col-masonry-container"
	);
	if (threeColMasonryContainerEl.length) {
		setTimeout(function () {
			threeColMasonryLayout();
		}, 100);
	}

	// Add current class to All News if on main News page
	function addCurrentToAllNews() {
		const allNewsLink = document.querySelector(".category-widget > p > a");

		allNewsLink.classList.add("current-cat");
	}
	const mainNewsPage = document.querySelector(".blog");
	if (mainNewsPage) {
		addCurrentToAllNews();
	}

	// Add class to elements when in viewport (for changing color)
	function backgroundVideoInViewport(element) {
		const el = document.querySelector(element);
		const observer = new window.IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					// console.log("Enter");
					el.classList.add("is-visible");
					return;
				} else {
					// console.log("Exit");
					el.classList.remove("is-visible");
				}
			},
			{
				root: null,
				threshold: 0.5
			}
		);

		observer.observe(el);
	}
	const backgroundVideoEl = document.querySelector(
		".acf-block.video.layout-background"
	);
	if (backgroundVideoEl) {
		backgroundVideoInViewport(".acf-block.video.layout-background");
	}

	// Add class to elements when in viewport (for animating elements in)
	// function addClassWithIntersectionObserver(els) {
	// 	const observer = new window.IntersectionObserver(
	// 		([entry]) => {
	// 			if (entry.isIntersecting) {
	// 				console.log("Enter");
	// 				console.log(entry.target);
	// 				entry.target.classList.add("animate-in");
	// 				observer.unobserve(entry.target);
	// 				return;
	// 			} else {
	// 				console.log("Exit");
	// 				console.log(entry.target);
	// 				// el.classList.remove("is-visible");
	// 			}
	// 		},
	// 		{
	// 			root: null,
	// 			// rootMargin: "",
	// 			threshold: 0.1
	// 		}
	// 	);

	// 	els.forEach((el) => {
	// 		observer.observe(el);
	// 	});
	// }
	// const servicesEls = document.querySelectorAll(
	// 	".acf-block.services .two-col-masonry-container > .service, .acf-block.news .two-col-masonry-container > .item, .blog .ast-archive-post, .archive .ast-archive-post"
	// );
	// if (servicesEls) {
	// 	setTimeout(function () {
	// 		addClassWithIntersectionObserver(servicesEls);
	// 	}, 200);
	// }
})(jQuery);
